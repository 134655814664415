<template>
  <div class="box" style="padding:80px;">
    <h3>外卖可用会员折扣</h3>
    <el-form
      class="b-card out-item"
        v-if="wmSet"
        v-model="wmSet"
        ref="form"
        label-width="80px"
        @submit.native.stop.prevent="wmSetFormSubmit('wmSet')"
      >
      <el-form-item>
      </el-form-item>
        <el-form-item label="是否启用">
          <el-radio-group v-model="wmSet.is_ky_member">
            <el-radio label="0">关闭</el-radio>
            <el-radio label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            size="medium"
            native-type="submit"
          >保存</el-button>
        </el-form-item>
      </el-form>

    <h3>外卖平台设置</h3>
    <el-form
      v-if="form"
      ref="form"
      label-width="80px"
      @submit.native.stop.prevent="formSubmit('form')"
    >
      <div
        class="b-card out-item"
        v-for="(item, index) in form"
        :key="index"
      >
        <el-form-item
          label="平台名称"
        >
          <el-select v-model="item.name" placeholder="请选择">
          <el-option
            v-for="item in PTS"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        </el-form-item>

        <el-form-item
          label="商户id"
          :rules="{
            required: true, message: '商户id不能为空', trigger: 'blur'
    }"
        >
          <el-input
            type="text"
            required
            v-model.trim="item.by1"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="id（KEY）"
          :rules="{
            required: true, message: 'd（KEY）不能为空', trigger: 'blur'
    }"
        >
          <el-input
            type="text"
            required
            v-model.trim="item.appKey"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="秘钥"
          :rules="{
            required: true, message: '秘钥不能为空', trigger: 'blur'
    }"
        >
          <el-input
            type="text"
            required
            show-password
            v-model.trim="item.appSecret"
          ></el-input>
        </el-form-item>

        <el-form-item label="是否启用">
          <el-radio-group v-model="item.is_off">
            <el-radio label="0">关闭</el-radio>
            <el-radio label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button
            class="out-del"
            type="text"
            icon="el-icon-delete"
            @click.prevent="removeBody(item)"
          >删除该项</el-button>
        </el-form-item>

      </div>
      <el-form-item>
        <el-button
          type="primary"
          size="medium"
          native-type="submit"
          :loading="Loading"
        >保存</el-button>

        <el-button
          plain
          icon="el-icon-plus"
          round
          @click="addBody"
        >新增</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SettingService from '@admin/services/SettingService'
import flatry from '@admin/utils/flatry'

export default {
  name: 'TakeOut',
  data () {
    return {
      form: null,
      wmSet: null,
      Loading: false,
      PTS: [
        {
          id: 1,
          name: '达达配送'
        },
        {
          id: 2,
          name: '蜂鸟配送'
        }
      ]
    }
  },
  async created () {
    let param = [
      {
        is_off: '0'
      }
    ]
    let wmSet = {
      is_ky_member: '0'
    }
    const { data } = await flatry(SettingService.getTakeOut())
    if (data) {
      this.form = data.data.form
      this.wmSet = { is_ky_member: data.data.wm }
    } else {
      this.form = param
      this.wmSet = wmSet
    }
  },
  methods: {
    removeBody (item) {
      let index = this.form.indexOf(item)
      if (index !== -1) {
        this.form.splice(index, 1)
      }
    },
    addBody () {
      let id = this.form.length
      if (id > 9) {
        this.$message.warning('不能超过10个，最大数量只支持10个:(')
        return
      }
      this.form.push({
        is_off: '0'
      })
    },
    async formSubmit () {
      const { data } = await flatry(SettingService.getTakeOut({ form: this.form }))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/store/wm' })
      }
    },
    async wmSetFormSubmit () {
      const { data } = await flatry(SettingService.getTakeOut({ wm: this.wmSet }))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/store/wm' })
      }
    }
  },

  watch: {}
}
</script>
<style lang='scss' scoped>
.out-item {
    margin-bottom: 20px;
}
.out-del {
    color: #f56c6c;
}
</style>
